import StarRatings from "react-star-ratings";
import {MDBListGroupItem} from "mdbreact";
import React from "react";

const ReviewItem = ({review}) => {

    return (
        <MDBListGroupItem style={{marginBottom: "1rem", backgroundColor: "#F9F9F9"}} className={"col-lg-12"}
                          key={review.id}>
            <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">{review.user}</h5>
                <small>{new Date(review.dateLong).toLocaleDateString()}</small>
            </div>
            <p className="mb-1">{review.comment}</p>

            <StarRatings numberOfStars={5}
                         rating={review.rate}
                         starRatedColor="#ffa000"
                         starDimension="30px"
            />
        </MDBListGroupItem>
    )
}

export default ReviewItem;
