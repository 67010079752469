import React, {useEffect} from "react";
import {MDBBtn, MDBCol, MDBIcon, MDBRow} from "mdbreact";
import Container from "react-bootstrap/Container";
import Product from "./product";
import firebase from "../firebase/firebase.js";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import {ProductActions} from "../store/actions/productActions";
import ReviewForm from "../reviews/ReviewForm";
import ReviewsGroup from "../reviews/ReviewsGroup";
import {CartActions} from "../store/actions/cartActions";
import {WishlistActions} from "../store/actions/wishlistActions";


const SingleProduct = () => {
    const dispatch = useDispatch();
    const {productId} = useParams();
    const product = useSelector(state => state.product.products.find(
        product => product.id === productId
    ));
    const user = useSelector(state => state.auth.user);

    useEffect(() => {
        if (!product)
            dispatch(ProductActions.fetchSingleProduct(productId))
    }, [])


    return (<section className="my-5" style={{background: "#FFFFFF"}}>
            <h2 className="h1-responsive font-weight-bold text-center my-5">
                {/*//todo : change productId to 'name'*/}
                {product && product.productid}
            </h2>
            <Container style={{
                padding: '2rem'
            }}>
                <MDBRow>
                    <MDBCol lg="5" className="text-center text-lg-left">
                        <img
                            className="img-fluid"
                            src={product && product.image}
                            alt={`${product && product.productid}`}
                            style={{height: "80%", maxHeight: "600px"}}
                        />
                    </MDBCol>
                    <MDBCol lg="7">
                        <MDBRow className="mb-3">
                            <MDBCol size="1">
                                <MDBIcon icon="share" size="lg" className="indigo-text"/>
                            </MDBCol>
                            <MDBCol xl="10" md="11" size="10">
                                <h5 className="font-weight-bold mb-3">Description</h5>
                                <p className="grey-text">
                                    {product && product.description}
                                </p>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mb-3">
                            <MDBCol size="1">
                                <MDBIcon icon="share" size="lg" className="indigo-text"/>
                            </MDBCol>
                            <MDBCol xl="10" md="11" size="10">
                                <h5 className="font-weight-bold mb-3">Quantity in
                                    storage: {product && product.countininventory}
                                </h5>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mb-3">
                            <MDBCol size="1">
                                <MDBIcon icon="share" size="lg" className="indigo-text"/>
                            </MDBCol>
                            <MDBCol xl="10" md="11" size="10">
                                <h5 className="font-weight-bold mb-3">Price: {product && product.price} MKD
                                </h5>
                            </MDBCol>
                            <MDBCol>
                                <MDBBtn color="success"
                                        onClick={() => CartActions.addCartProduct(product, user.uid)}>
                                    Add to cart
                                </MDBBtn>
                                <MDBBtn color="amber"
                                        onClick={() => WishlistActions.addWishlistProduct(product, user.uid)}>
                                    Wishlist
                                </MDBBtn>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
                {firebase.auth().currentUser != null && <ReviewForm productId={productId}/>}
                <ReviewsGroup productId={productId}/>
            </Container>
        </section>
    );


}

export default SingleProduct;
