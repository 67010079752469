import React, {Component} from "react";
import Project from "./project";
import Repository from "../repository/repository";


class ProjectsGroup extends Component {


    constructor(props) {
        super(props);
        this.state = {
            projects: [],
        }
    }

    componentDidMount() {
        Repository.getProjects().then((projects) => {
            this.setState({projects: projects});
        })
    }

    Projects = () => {
        let arr = [];

        for (let i = 0; i < this.state.projects.length; i++) {
            let project = this.state.projects[i];
            arr.push(
                <Project
                    image={project.image}
                    description={project.description}
                    title={project.name}
                    key={i.toString()}
                />
            );
        }
        return arr;
    };


    render() {
        return (
            <React.Fragment>
                <div className="container-fluid bg-3 text-center">
                    <h3>Projects</h3><br/>

                    <div className={"row"}>
                        {this.Projects()}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ProjectsGroup;
