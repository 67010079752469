import {Nav, Navbar, NavDropdown} from "react-bootstrap/";
// import '../bootstrap/bootstrap.min.css';
import React, {useEffect, useState} from "react";
import firebase from "../firebase/firebase";
import {Link, useHistory} from "react-router-dom";
import admins from "../admin/admins.json"
import {makeStyles} from "@material-ui/core";
import MaterialLink from "@material-ui/core/Link";
import AdminDropDown from "../admin/AdminDropDown";
import {FavoriteBorder, LocalShipping, ShoppingCart} from "@material-ui/icons";

const useStyles = makeStyles({
    root: {}
})

const Header = (props) => {
    const classes = useStyles();
    var history = useHistory();
    const [admin, setAdmin] = useState(null);
    const uiConfig = {
        signInFlow: "popup",
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        ],
        callbacks: {
            signInSuccess: () => false
        }
    }


    useEffect(() => {
        firebase.auth().onAuthStateChanged(() => {
            checkAdmin();
        })
    }, [])

    const checkAdmin = () => {
        if (firebase.auth().currentUser && admins.includes(firebase.auth().currentUser.uid)) {
            setAdmin(
                <AdminDropDown/>
            )
        } else
            setAdmin(null);
    }

    let signIn;
    const provider = new firebase.auth.GoogleAuthProvider();
    if (localStorage.getItem("uid") == null && !props.logInState)
        signIn =
            <MaterialLink onClick={() => {
                firebase.auth().signInWithPopup(provider);
            }}
                          variant={"container"}
                          className="linkColor linkMargin">
                Sign In
            </MaterialLink>
    else {
        signIn =
            <MaterialLink component={Link} onClick={() => {
                firebase.auth().signOut();
                localStorage.removeItem("uid");
                history.push("/");
            }}
                          className="linkColor linkMargin">
                Sign Out&nbsp;
            </MaterialLink>
    }


    return (
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" style={{fontSize: '1.3rem'}}>
            <Navbar.Brand>
                <MaterialLink component={Link} to="/" className="linkColor linkMargin">
                    Videosonik
                </MaterialLink></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    <MaterialLink component={Link} to="/projects" className="linkColor linkMargin">
                        &nbsp; Тотеми&nbsp;
                    </MaterialLink>
                    <MaterialLink component={Link} to="/contact" className="linkColor linkMargin">
                        &nbsp; Контакт&nbsp;
                    </MaterialLink>
                    <MaterialLink component={Link} to="/dostava" className="linkColor linkMargin">
                        &nbsp; Достава&nbsp;
                        <LocalShipping/>
                    </MaterialLink>
                    {/*<Nav.Link href="">Pricing</Nav.Link>*/}
                </Nav>
                <Nav>
                    <MaterialLink component={Link} to="/wishlist" className="linkColor linkMargin">
                        <FavoriteBorder/>
                        &nbsp; Омилени&nbsp;
                    </MaterialLink>
                    <MaterialLink component={Link} to="/cart" className="linkColor linkMargin">
                        <ShoppingCart/>
                        &nbsp; Кошница&nbsp;
                    </MaterialLink>
                    {signIn}
                    {admin}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
};
export default Header;

