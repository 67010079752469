import firebase from "../../firebase/firebase";
import {ADD_CART_PRODUCT, DELETE_CART_PRODUCT, FETCH_CART_PRODUCTS, SET_PRODUCT_QUANTITY} from "./actionTypes";

const db = firebase.firestore();


export const CartActions = {
    fetchAllCartProducts: userId => dispatch => {
        db.collection("users")
            .doc(userId)
            .collection("cart")
            .get()
            .then(data => {
                dispatch({
                    type: FETCH_CART_PRODUCTS,
                    cart: data.docs.map(doc => {
                        return {
                            ...doc.data(),
                            id: doc.id
                        }
                    })
                })
            });
    },
    addCartProduct: (product, userId) => dispatch => {
        product.quantity = 1;
        db.collection("users")
            .doc(userId)
            .collection("cart")
            .add(product)
            .then((docRef) => {
                dispatch({
                    type: ADD_CART_PRODUCT,
                    product: {
                        ...product,
                        id: docRef.id
                    }
                })
            })
    },
    deleteCartProduct: (productId, userId) => dispatch => {
        db.collection("users")
            .doc(userId)
            .collection("cart")
            .doc(productId)
            .delete()
            .then(() => {
                dispatch({
                    type: DELETE_CART_PRODUCT,
                    productId
                })
            });
    },
    setQuantity: (productId, userId, quantity) => dispatch => {
        db.collection("users")
            .doc(userId)
            .collection("cart")
            .doc(productId)
            .update({
                quantity
            })
            .then(()=>{
                dispatch({
                    type: SET_PRODUCT_QUANTITY,
                    productId,
                    quantity
                })
            })
    }
}
