import firebase from "firebase";


const firebaseConfig = {
    apiKey: "AIzaSyCYtKVLJb2iAd7PEB6Mj4it6i3xhjkkjIc",
    authDomain: "videosonik-e065a.firebaseapp.com",
    databaseURL: "https://videosonik-e065a.firebaseio.com",
    projectId: "videosonik-e065a",
    storageBucket: "videosonik-e065a.appspot.com",
    messagingSenderId: "154756637037",
    appId: "1:154756637037:web:61d07b1ecd6166b2a73a64",
    measurementId: "G-7QLJ2SFSZV"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
