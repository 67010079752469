import firebase from "../../firebase/firebase";
import {
    ADD_PRODUCT,
    ADD_PRODUCT_REVIEW,
    DELETE_PRODUCT,
    FETCH_ALL_PRODUCTS,
    FETCH_PRODUCT,
    FETCH_PRODUCT_REVIEWS,
    UPDATE_PRODUCT
} from "./actionTypes";

const db = firebase.firestore();

export const ProductActions = {
    fetchProducts: () => dispatch => {
        db.collection("products")
            .get()
            .then(data => {
                dispatch({
                    type: FETCH_ALL_PRODUCTS,
                    products: data.docs.map(doc => {
                        return {
                            ...doc.data(),
                            id: doc.id
                        }
                    })
                })
            });
    },
    fetchSingleProduct: id => dispatch => {
        const name = decodeURI(id);
        return db.collection("products")
            .doc(name)
            .get()
            .then((doc) => {
                dispatch({
                    type: FETCH_PRODUCT,
                    product: doc.data(),
                    id: doc.id
                })
            });
    },
    fetchProductReviews: id => dispatch => {
        const id = decodeURI(id)
        db.collection("reviews")
            .where('productId', '==', id)
            .get()
            .then((data) => {
                dispatch({
                    type: FETCH_PRODUCT_REVIEWS,
                    reviews: data.docs.map(doc => {
                        return {
                            ...doc.data(),
                            id: doc.id
                        }
                    }),
                    productId: id
                })
            });
    },
    addProductReview: review => dispatch => {
        db.collection("reviews")
            .add(review)
            .then(docRef => {
                dispatch({
                    type: ADD_PRODUCT_REVIEW,
                    review: {
                        ...review,
                        id: docRef.id
                    }
                })
            });
    },
    addProduct: product => dispatch => {
        db.collection("products")
            .add(product).then(docRef => {
            dispatch({
                type: ADD_PRODUCT,
                product: {
                    ...product,
                    id: docRef.id
                }
            })
        });
    },
    deleteProduct: id => dispatch => {
        db.collection("products")
            .doc(id)
            .delete()
            .then(() => {
                dispatch({
                    type: DELETE_PRODUCT,
                    id
                })
            });
    },
    updateProduct: (id, product) => dispatch => {
        db.collection("products")
            .doc(id)
            .set(product)
            .then(() => {
                dispatch({
                    type: UPDATE_PRODUCT,
                    product
                })
            });
    }
}
