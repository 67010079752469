import React, {useEffect} from "react";
import {ProductActions} from "../store/actions/productActions";
import {useDispatch, useSelector} from "react-redux";
import ReviewItem from "./ReviewItem";
import {Container} from "react-bootstrap";

const ReviewsGroup = ({productId}) => {
    const dispatch = useDispatch();
    const reviews = useSelector(state => state.product.reviews.filter(
        review => review.productId === productId
    ));

    useEffect(() => {
        if (!reviews)
            dispatch(ProductActions.fetchProductReviews(productId))
    }, [])

    return (
        <Container>
            {reviews && reviews.map(review => <ReviewItem review={review}/>)}
        </Container>
    );
}
export default ReviewsGroup;
