import Card from "react-bootstrap/Card";
import React from "react";
import {Button, Image} from "react-bootstrap";
import '../App.css';
//import '../bootstrap/bootstrap.min.css';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {CartActions} from "../store/actions/cartActions";
import {WishlistActions} from "../store/actions/wishlistActions";

const WishlistItem = ({product, onDelete}) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user)

    const addToCart = () => {
        if (user && user.uid) {
            dispatch(CartActions.addCartProduct(product, user.uid));
            dispatch(WishlistActions.deleteWishlistProduct(product.id, user.uid));
        }
    };

    return (
        <div className={"col-lg-4 col-md-4 mb-4"}>
            <Card className={"likiclass"} style={{height: '100%'}} key={product && product.id}>
                <Card.Header>{product && product.name}</Card.Header>
                <div style={{width: "100%", height: "70%"}}>
                    <Card.Body>
                        <Link to={"product/" + product && product.id}>
                            <Image src={product && product.image}
                                   width={"100%"}
                                   height={"70%"}
                                   alt={product && product.name}
                            />
                        </Link>
                        {product && product.description && product.description.substring(0, 55)}
                    </Card.Body>
                </div>
                <Card.Body as={'h5'}>
                    {product && product.price} MKD
                </Card.Body>
                <Card.Footer className={"d-flex"}>
                    <Button style={{marginRight: '1rem', padding: "0.7rem"}} variant={'success'}
                            onClick={addToCart}>
                        Add to cart!
                    </Button>
                    <button className="btn btn-deep-orange"
                            type="button"
                            style={{padding: "0.7rem"}}
                            onClick={() => onDelete(product.id)}>
                        Remove from wishlist
                    </button>
                    {/*<Button style={{marginLeft: '1rem'}} variant={'success'} size={"md"}*/}
                    {/*onClick={() => Product.addToWishlist(this.props.value.productid)}>*/}
                    {/*Wishlist!*/}
                    {/*</Button>*/}
                </Card.Footer>
            </Card>
        </div>
    );
}

export default WishlistItem;

//
// <Card className={classes.root}>
//     <CardActionArea>
//         <CardMedia
//             className={classes.media}
//             image="/static/images/cards/contemplative-reptile.jpg"
//             title="Contemplative Reptile"
//         />
//         <CardContent>
//             <Typography gutterBottom variant="h5" component="h2">
//                 Lizard
//             </Typography>
//             <Typography variant="body2" color="textSecondary" component="p">
//                 Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
//                 across all continents except Antarctica
//             </Typography>
//         </CardContent>
//     </CardActionArea>
//     <CardActions>
//         <Button size="small" color="primary">
//             Share
//         </Button>
//         <Button size="small" color="primary">
//             Learn More
//         </Button>
//     </CardActions>
// </Card>
