import axios from '../axios/custom-axios';
import firebase from "../firebase/firebase";
import storage from "../firebase/storage";
import emailjs from 'emailjs-com';


const db = firebase.firestore();
const email = emailjs.init("user_hXKYUIitIabsGyswvBnnG");
const Repository = {

    getAllProducts: async () => {
        const data = await db.collection("products").get();

        return {data: data.docs.map(doc => doc.data())};
        // return await axios.get('/products');
    },
    getCartProducts: async () => {
        const user = localStorage.getItem("uid")
        const data = await db.collection("users")
            .doc(user)
            .collection("cart")
            .get();

        return {data: data.docs.map(doc => doc.data())};

    },
    Login: async (username, pass) => {
        return axios({
            method: 'post',
            url: '/authenticate',
            data: {username: username + "", pass: pass + ""}
        }).then(response => {
            localStorage.setItem("token", response.data.token);
        });
    },
    addToWishlist: (product) => {
        const name = product.productid;
        const data = db.collection("users")
            .doc(localStorage.getItem("uid"))
            .collection("wishlist")
            .doc(name)
            .set(product);
    },

    getWishlist: async () => {
        const data = await db.collection("users")
            .doc(localStorage.getItem("uid"))
            .collection("wishlist")
            .get();

        return data.docs.map(doc => doc.data());
    },
    deleteWishlistItem: (productId) => {
        return db.collection("users")
            .doc(localStorage.getItem("uid"))
            .collection("wishlist")
            .doc(productId)
            .delete();

    },
    getSingleProduct: (id) => {
        const name = decodeURI(id);
        return db.collection("products")
            .doc(name)
            .get()
            .then((doc) => doc.data());
    },
    postContact: (contactData) => {
        return emailjs.send("videosonik", "template_9nNGHFco", contactData, "user_hXKYUIitIabsGyswvBnnG")
            .then(function (response) {
                console.log('SUCCESS!', response.status, response.text);
                return true;
            }, function (err) {
                console.log('FAILED...', err);
                return false;
            });
        /*return axios({
            method: 'post',
            url: '/contact/send',
            data: contactData
        }).catch((resp) => {
            Repository.logout();
        });*/
    },
    getAllContacts: () => {
        return axios({
            method: 'get',
            url: '/contact/all',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            },
        }).then(x => {
            return x.data;
        });
    },
    getAllReviewsForProduct: async (product) => {
        const name = decodeURI(product)
        const data = await db.collection("products")
            .doc(name)
            .collection("reviews")
            .get();

        if (data.docs.length > 0)
            return data.docs.map(doc => doc.data());
        return []


        //data.docs.map(doc => doc.data())
        // return axios.get(
        //     '/review?productId=' + product).then(x => {
        //     console.log("REVIEWS");
        //     console.log(x);
        //     return x.data;
        // }).catch((resp) => {
        //     Repository.logout();
        // });
    },
    postReview: async (review, productName) => {
        // const name = review.product.productid;
        await db.collection("products")
            .doc(productName)
            .collection("reviews")
            .add({
                comment: review.comment,
                rate: review.rate,
                dateLong: review.dateLong,
                user: review.user
            });

        // return axios({
        //     method: 'post',
        //     url: '/review',
        //     data: review,
        //     headers: {
        //         'Authorization': 'Bearer ' + localStorage.getItem("token"),
        //         // 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        //     }
        // }).catch((resp) => {
        //     Repository.logout();
        // });
    },
    postProduct: async (product) => {
        await db.collection("products")
            .doc(product.productid)
            .set(product);
        return true;

        /*  return await axios({
              method: 'post',
              url: '/products/new',
              data: product,
              headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem("token"),

              }
          });*/
    },
    deleteCartProduct: async (productId) => {
        return db.collection("users")
            .doc(localStorage.getItem("uid"))
            .collection("cart")
            .doc(productId)
            .delete();

        // return axios({
        //      method: 'delete',
        //      url: '/products/cart/' + productId,
        //      headers: {
        //          'Authorization': 'Bearer ' + localStorage.getItem("token")
        //      },
        //  });
    },
    addProductImage: async (image) => {
        await storage.ref(`images/${image.name}`).put(image);
        return await storage.ref(`images/${image.name}`).getDownloadURL();
    },
    getProductImage: async (image) => {
        return await storage.ref(`images/${image.name}`).getDownloadURL();
    },
    addCartProduct: async (productId) => {
        const name = productId.productid;
        productId.quantity = 1;
        const data = await db.collection("users")
            .doc(localStorage.getItem("uid"))
            .collection("cart")
            .doc(name)
            .set(productId);

        // return axios({
        //     method: 'put',
        //     url: '/products/cart/' + productid + "?quantity=" + 1,
        //     headers: {
        //         'Authorization': 'Bearer ' + localStorage.getItem("token")
        //     },
        // });
    },
    deleteProduct: async (passedProductId) => {
        const name = decodeURI(passedProductId)
        await db.collection("products")
            .doc(name)
            .delete();
    },
    patchProduct: async (product) => {
        const name = product.productid;
        await db.collection("products")
            .doc(name)
            .set(product);
    },
    processCart: async () => {
        const data = await db.collection("users")
            .doc(localStorage.getItem("uid"))
            .collection("cart")
            .get();

        data.docs.map(doc => doc.ref.delete());

        // docs.docs.map((doc)=>{doc.ref.delete();})


        /*        return axios({
                    method: 'get',
                    url: '/cart/process',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem("token")
                    },
                }).then(x => {
                    return x.data;
                }).catch((resp) => {
                    Repository.logout();
                });*/
    },
    getProjects: async () => {
        const data = await db.collection("projects").get();

        return data.docs.map(doc => doc.data());
    }
};
export default Repository
