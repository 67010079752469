import firebase from "../../firebase/firebase";
import {ADD_WISHLIST_PRODUCT, DELETE_WISHLIST_PRODUCT, FETCH_WISHLIST_PRODUCTS} from "./actionTypes";

const db = firebase.firestore();


export const WishlistActions = {
    fetchAllWishlistProducts: userId => dispatch => {
        db.collection("users")
            .doc(userId)
            .collection("wishlist")
            .get()
            .then((data) => {
                dispatch({
                    type: FETCH_WISHLIST_PRODUCTS,
                    wishlist: data.docs.map(doc => {
                        return {
                            ...doc.data(),
                            id: doc.id
                        }
                    })
                })
            });
    },
    addWishlistProduct: (product, userId) => dispatch => {
        db.collection("users")
            .doc(userId)
            .collection("wishlist")
            .add(product)
            .then((docRef) => {
                dispatch({
                    type: ADD_WISHLIST_PRODUCT,
                    product: {
                        ...product,
                        id: docRef.id
                    }
                })
            });
    },
    deleteWishlistProduct: (productId, userId) => dispatch => {
        db.collection("users")
            .doc(userId)
            .collection("wishlist")
            .doc(productId)
            .delete()
            .then(() => {
                dispatch({
                    type: DELETE_WISHLIST_PRODUCT,
                    productId
                })
            });
    }
}
