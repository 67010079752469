import Card from "react-bootstrap/Card";
import React, {useEffect, useState} from "react";
import {Button, Image} from "react-bootstrap";
import '../App.css';
import 'rc-input-number/assets/index.css';
import {Link} from "react-router-dom";

const Cart = ({product, onDelete, onQuantityChange, addToWishList}) => {

    const [state, setState] = useState({
            image: 'url',
            quantity: 0
        }
    );

    useEffect(() => {
        if (product) {
            setState({
                ...state,
                quantity: product.quantity
            })
        }
    }, [])

    const decrease = (e) => {
        e.preventDefault();
        setState({
            ...state,
            quantity: state.quantity - 1
        });
        editQuantityUpwards(state.quantity - 1);
    };

    const increase = (e) => {
        e.preventDefault();
        setState({
            ...state,
            quantity: state.quantity + 1
        });
        editQuantityUpwards(state.quantity + 1);
    };

    const setQuantity = (e) => {
        setState({
            ...state,
            quantity: e.target.value
        });
        // pass changes to parent
        editQuantityUpwards(e.target.value);
    };

    const editQuantityUpwards = (quantity) => {
        product.quantity = quantity;
        onQuantityChange(product);
    }

    return (
        <div className={"col-lg-3 col-md-4 mb-4"}>
            <Card className={"likiclass"} style={{height: '100%'}} key={product.id + "1"}>
                <Card.Header>{product && product.name}</Card.Header>
                <div style={{width: "100%", height: "70%"}}>
                    <Card.Body>
                        <Link to={"product/" + product.id}>
                            <Image src={product && product.image}
                                   style={{width: "205px", height: "205px"}}
                                   alt={product.id}
                            />
                        </Link>
                        <button className="btn pmd-btn-fab pmd-ripple-effect btn-outline-danger showme"
                                type="button"
                                style={{position: "absolute", top: "205px", right: "10px"}}
                                onClick={addToWishList}>
                            <i className="material-icons pmd-sm ">favorite_border</i></button>

                        {product && product.description && product.description.substring(0, 55)}
                    </Card.Body>
                </div>
                <Card.Body as={'h5'}>
                    {product && product.price} MKD
                </Card.Body>
                <Card.Footer style={{overflow: "autp"}} className={"d-flex"}>
                    <Button style={{marginRight: '1rem', display: "inline-block"}} variant={'danger'}
                            onClick={() => onDelete(product.id)}>
                        Remove from cart
                    </Button>
                    <div className="list-group def-number-input number-input" style={{display: "inline-block"}}>
                        <button onClick={increase} className="plus list-group-item text-center"/>
                        <input className="quantity list-group-item" name="quantity"
                               value={product && state.quantity}
                               onChange={setQuantity}
                               type="number"/>
                        <button onClick={decrease} className="minus list-group-item"/>
                    </div>
                </Card.Footer>
            </Card>
        </div>
    );

}

export default Cart;


// {/*<Col className="col-lg-4 col-md-6 mb-4  ">*/}
//     {/*<Card border={'info'} className={"likiclass"}>*/}
//         {/*<Card.Header>{this.props.value.productid.productid}</Card.Header>*/}
//         {/*<Card.Body as={'h5'}>*/}
//             {/*{this.props.value.productid.price} MKD<br/>*/}
//             {/*{this.props.value.quantity}*/}
//         {/*</Card.Body>*/}
//         {/*<Card.Footer>*/}
//             {/*<Button variant={'warning'} onClick={() => Cart.editQuantity(this.props.value.productid.productid)}>*/}
//                 {/*Change quantity*/}
//             {/*</Button>*/}
//             {/*<Button variant={'danger'} onClick={() => Cart.editQuantity(this.props.value.productid.productid)}>*/}
//                 {/*Delete from cart*/}
//             {/*</Button>*/}
//         {/*</Card.Footer>*/}
//     {/*</Card>*/}
// {/*</Col>*/}
