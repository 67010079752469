import React, {useEffect, useState} from 'react';
import './App.css';
import {Container} from "react-bootstrap";
import './bootstrap/bootstrap.min.css';
import './bootstrap/bootstrap-grid.min.css'
import 'mdbreact/dist/css/mdb.css';
import Row from "react-bootstrap/Row";
import {BrowserRouter as Router, Route} from 'react-router-dom'
import Products from './products/productGroup';
import Header from "./header/header";
import ProductsNavigator from "./header/navigator";
import CartGroup from "./cart/cartGroup";
import WishlistGroup from "./wishlist/WishlistGroup";
import SingleProduct from "./products/SingleProduct";
import Contact from "./contact/Contact";
import ContactGroup from "./contact/ContactGroup";
import AdminPanel from "./admin/AdminPanel";
import EditAddProduct from "./admin/EditAddProduct";
import Spinner from "react-bootstrap/Spinner";
import firebase from "./firebase/firebase.js";
import ProjectsGroup from "./projects/projectsGroup";
import Dostava from "./dostava/Dostava";
import {useDispatch, useSelector} from "react-redux";
import {ProductActions} from "./store/actions/productActions";
import {AuthActions} from "./store/actions/authActions";


const App = () => {
    const dispatch = useDispatch();

    const products = useSelector(state => state.product.products);

    const [state, setState] = useState({
        products: [],
        admin: false,
        pageNumber: 0,
        pageSize: 3,
        isLoaded: false,
        isLoggedIn: false
    })
    useEffect(() => {
        firebase.auth().onAuthStateChanged(user => {
            dispatch(AuthActions.setAuthState(user));
            if (user && user.uid != null)
                setState({...state, isLoggedIn: true})
            else
                setState({...state, isLoggedIn: false})
        })

        dispatch(ProductActions.fetchProducts());
    }, [])

    console.log(products)
    return (
        <div style={{background: "#F9F9F9", width: "100%", height: "100%"}}>
            <Router>
                <Header logInState={state.isLoggedIn}/>
                <Route path={"/"} exact>
                    <Row style={{marginTop: '2rem'}}>
                        <ProductsNavigator/>
                        <Container style={{padding: 0, marginLeft: "1rem"}}>
                            {!products ? (
                                    <Spinner animation="border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </Spinner>) :
                                <Products/>
                            }
                        </Container>
                    </Row>
                </Route>
                <Route path={"/product/:productId"} exact>
                    <SingleProduct/>
                </Route>
                <Route path={"/led"} exact>
                    <Row style={{marginTop: '2rem'}}>
                        <ProductsNavigator/>
                        <Container style={{padding: 0, marginLeft: 0}}>
                            {!products ? (
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>) : (
                                <Products category={'LED'}/>
                            )}
                        </Container>
                    </Row>
                </Route>
                <Route path={"/computers"} exact>
                    <Row style={{marginTop: '2rem'}}>
                        <ProductsNavigator/>
                        <Container style={{padding: 0, marginLeft: 0}}>
                            {!products ? (
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>) : (
                                <Products category={'Computers'}/>
                            )}
                        </Container>
                    </Row>
                </Route>
                <Route path={"/plumbing"} exact>
                    <Row style={{marginTop: '2rem'}}>
                        <ProductsNavigator/>
                        <Container style={{padding: 0, marginLeft: 0}}>
                            {!products ? (
                                    <Spinner animation="border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </Spinner>) :
                                <Products category={'Plumbing'}/>}
                        </Container>
                    </Row>
                </Route>
                <Route path={"/wishlist"}>
                    <WishlistGroup/>
                </Route>
                <Route path={"/cart"} exact>
                    <CartGroup/>
                </Route>
                <Route path={"/contact"}>
                    <Contact/>
                </Route>
                <Route path={"/admin/panel"}>
                    <AdminPanel/>
                </Route>
                <Route path={"/admin/newproduct"}>
                    <EditAddProduct/>
                </Route>
                <Route path={"/admin/editproduct/:productId"}>
                    <EditAddProduct/>
                </Route>
                <Route path={"/admin/contacts"}>
                    <ContactGroup/>
                </Route>
                <Route path={"/projects"}>
                    <ProjectsGroup/>
                </Route>
                <Route path={"/dostava"}>
                    <Dostava/>
                </Route>
            </Router>
        </div>
    );
}

export default App;
