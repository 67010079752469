import React, {useState} from "react";
import Product from "./product";
import Row from "react-bootstrap/Row";
import ReactPaginate from 'react-paginate';
import {Dropdown, DropdownButton} from "react-bootstrap";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import "../bootstrap/bootstrap.min.css"
import {useDispatch, useSelector} from "react-redux";
import {CartActions} from "../store/actions/cartActions";
import {WishlistActions} from "../store/actions/wishlistActions";


const Products = ({category}) => {
    const products = useSelector(state => {
        if (category) return state.product.products
            .filter(product => product.category === category)
        else
            return state.product.products;
    });
    const user = useSelector(state => state.auth.user);
    const dispatch = useDispatch();

    const [state, setState] = useState({
        pageSize: 16,
        pageNumber: 0,
        pagedProducts: products && products.slice(0, 16)
    })

    const addToCart = (product) => {
        if (user && user.uid)
            dispatch(CartActions.addCartProduct(product, user.uid))
    }

    const addToWishlist = (product) => {
        if (user && user.uid)
            dispatch(WishlistActions.addWishlistProduct(product, user.uid))
    }

    const generateProducts = () => {
        let arr = [];
        const offset = state.pageNumber * state.pageSize;
        const pagedProducts = products.slice(offset, offset + state.pageSize);
        for (let i = 0; i < pagedProducts.length; i++) {
            arr.push(
                <div className={"col-lg-3 col-md-4 col-sm-12 mb-4"}>
                    <Product product={pagedProducts[i]}
                             key={pagedProducts[i].id}
                             addToCart={addToCart}
                             addToWishlist={addToWishlist}/>
                </div>
            );
        }
        return arr;
    };

    const handlePageClick = (e) => {
        setState({
            ...state,
            pageNumber: e.selected,
        });
    };

    const onPageSize = (e) => {
        if (e.target.text != null) {
            setState({
                pageSize: parseInt(e.target.text),
                pageNumber: 0,
            })
        }
    }
    const onSearchChange = (e) => {
        let term = e.target.value;
        let filteredProducts = products.filter(product => {
            return product.description.toLowerCase().includes(term.toLowerCase()) ||
                product.productid.toLowerCase().includes(term.toLowerCase());

        })
        let sliced = filteredProducts.slice(0, state.pageSize);

        setState({
            pageNumber: 0,
            pagedProducts: sliced
        });
    }

    let pageCount = Math.ceil(products.length / state.pageSize);
    return (
        <React.Fragment>
            <DropdownButton
                as={ButtonGroup}
                key={"down"}
                id={`dropdown-button-drop-down`}
                drop={"down"}
                variant="dark"
                title={`${state.pageSize} Per page `}
                onClick={onPageSize}
            >
                <Dropdown.Item eventKey="1">4</Dropdown.Item>
                <Dropdown.Item eventKey="2">8</Dropdown.Item>
                <Dropdown.Item eventKey="3">16</Dropdown.Item>
                <Dropdown.Item eventKey="4">32</Dropdown.Item>
                <Dropdown.Item eventKey="5">100</Dropdown.Item>
            </DropdownButton>
            <input type={"text"} placeholder={"Search"} onChange={onSearchChange}/>
            <Row style={{marginTop: "1rem"}}>
                {products && generateProducts()}
            </Row>
            <ReactPaginate previousLabel={"previous"}
                           nextLabel={"next"}
                           breakLabel={<span className="gap">...</span>}
                           breakClassName={"break-me"}
                           pageCount={pageCount}
                           marginPagesDisplayed={2}
                           pageRangeDisplayed={5}
                           pageClassName={"page-item"}
                           pageLinkClassName={"page-link"}
                           previousClassName={"page-item"}
                           nextClassName={"page-item"}
                           previousLinkClassName={"page-link"}
                           nextLinkClassName={"page-link"}
                           forcePage={state.pageNumber}
                           onPageChange={handlePageClick}
                           containerClassName={"pagination justify-content-center"}
                           activeClassName={"active"}/>
        </React.Fragment>
    );
}

export default Products;
