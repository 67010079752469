import {ADD_WISHLIST_PRODUCT, DELETE_WISHLIST_PRODUCT, FETCH_WISHLIST_PRODUCTS} from "../actions/actionTypes";

const initialState = {
    wishlist: []
}

export const wishlistReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_WISHLIST_PRODUCTS: {
            return {
                ...state,
                wishlist: action.wishlist
            }
        }
        case ADD_WISHLIST_PRODUCT: {
            return {
                ...state,
                wishlist: [state.wishlist, action.product]
            }
        }
        case DELETE_WISHLIST_PRODUCT: {
            const newWishlist = state.wishlist.filter(product => product.id !== action.productId);
            return {
                ...state,
                wishlist: newWishlist
            }
        }
        default:
            return state;
    }
}
