import React, {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Cart from "./cart";
import {Button, Container} from "react-bootstrap";
import Typography from "@material-ui/core/Typography/Typography";
import Card from "@material-ui/core/Card/Card";
import '../background-color.css'
import {useDispatch, useSelector} from "react-redux";
import {CartActions} from "../store/actions/cartActions";
import {WishlistActions} from "../store/actions/wishlistActions";
import {Link} from "react-router-dom";

const CartGroup = () => {

    const dispatch = useDispatch();
    const cart = useSelector(state => state.cart.cart)
    const user = useSelector(state => state.auth.user)
    const [fakeProcessDiv, setFakeProcessDiv] = useState(true);
    useEffect(() => {
        if (user && user.uid) {
            dispatch(CartActions.fetchAllCartProducts(user.uid))
        }
    }, [user])

    const deleteCartGroupItem = (productId) => {
        if (user && user.uid) {
            dispatch(CartActions.deleteCartProduct(productId, user.uid))
        }
    };

    //todo: implement
    const onQuantityChange = (product) => {
        /*const arr = this.state.products.map((x) => {
            if (x.productid == product.productid) {
                return product;
            } else return x;
        });
        this.setState({
            products: arr
        });*/
    };

    const process = () => {
        alert("success")
        // Repository.processCart();
        // window.location.reload();
    };

    const totalPrice = () => {
        if (cart) {
            let total = 0;
            for (let i = 0; i < cart.length; i++) {
                total += cart[i].quantity * cart[i].price;
            }
            return total;
        }
    };

    const addToWishList = (product) => {
        if (user && user.uid) {
            dispatch(WishlistActions.addWishlistProduct(product, user.uid));
        }
    }

    return (
        <React.Fragment>
            {
                !user ?
                    <Container style={{textAlign: "center", paddingTop: "3rem"}}>
                        <h1>
                            Sign in for this Feature
                        </h1>
                    </Container> :
                    <Row>
                        <Container>
                            <Card className={"align-center"} style={{textAlign: 'center', marginTop: '1rem'}}>
                                <Typography variant="h3" component="h3">
                                    Your cart
                                </Typography>
                            </Card>
                            <Row style={{marginTop: "2rem"}}>
                                {cart && cart.map(cartItem => {
                                        return <Cart product={cartItem} key={cartItem.id}
                                                     onDelete={deleteCartGroupItem}
                                                     onQuantityChange={onQuantityChange}
                                                     addToWishList={addToWishList}
                                        />
                                    }
                                )}
                            </Row>
                            {
                                !cart || (cart && cart.length === 0) ?
                                    <div style={{textAlign: "center", alignContent: "center"}}>
                                        <h1 style={{}}> Your cart is empty </h1>
                                        <img src={"/empty-cart.png"}
                                             style={{width: "50%", height: "50%"}}/>
                                    </div> :
                                    null
                            }
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "3rem",
                                marginBottom: "3rem"
                            }}>
                                <Link variant={"dark"}
                                      className='btn btn-dark'
                                      style={{alignItems: "col-md-3", float: "left", fontSize: "1rem"}}
                                      to="/"
                                >
                                    Back to products
                                </Link>
                                <h1 className={"col-md-6"}>Total price: {totalPrice()} MKD</h1>
                                <div className="alert alert-warning container" role="alert"
                                     hidden={fakeProcessDiv}>
                                    <strong>Извинете!</strong> Оваа опција моментално
                                    е недостапна, но можете да ја
                                    користите како калкулатор
                                </div>
                                <Button variant={"success"}
                                        style={{alignItems: "col-md-3", float: "right", fontSize: "1rem"}}
                                        onClick={() => {
                                            setFakeProcessDiv(false);
                                        }}>
                                    Go to payment
                                </Button>
                            </div>
                        </Container>
                    </Row>
            }
        </React.Fragment>
    );
};

export default CartGroup;
