import React from "react";
import {Link} from "react-router-dom";
import '../App.css';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";


const ProductsNavigator = (props) => {

// todo: ako e ekranot <1200 , flex direction : row na divot
    var flag = 'column';
    let justifyContentFlag;
    if (window.innerWidth < 1200) {
        flag = 'row';
        justifyContentFlag = "space-between";
    }

    return (

        /*<MDBNav className="list-group font-weight-bold col-lg-2 col-md-3 col-sm-6 col-xs-3 offset-1 align-content-center"
                style={{fontSize:"1.5rem",marginTop:"5rem",flexDirection:flag, justifyContent:justifyContentFlag}}>
            <Link to={"/"}>
                <div className="alert-link likiclass text-dark">All</div>
            </Link>
            
            <Link to={"/led"}>
                <div className="alert-link likiclass text-dark">LED</div>
            </Link>
            <Link to={"/plumbing"}>
                <div className="alert-link likiclass text-dark">Plumbing</div>
            </Link>
            <NavLink to={"/computers"}>
                <div className="alert-link likiclass text-dark">Computers</div>
            </NavLink>
        </MDBNav>*/
        <div
            className="list-group font-weight-bold col-lg-2 col-md-3 col-sm-6 col-xs-3 offset-2 align-content-center menu-bar-left"
            style={{
                fontSize: "1.5rem",
                flexDirection: flag,
                justifyContent: justifyContentFlag,
            }}>
            <List title="Products" id="collapsible-nav-dropdown"
                  MenuProps={{disableScrollLock: true}}
                  className="menu-bar-left">
                <ListItem component={Link}>
                    <Link to="/" className={"dropdown-item"}>All</Link>
                </ListItem>
                <ListItem component={Link} to={"led"} linkButton>
                    <Link to="/led" className={"dropdown-item"}>LED</Link>
                </ListItem>
                <ListItem component={Link} to={"plumbing"} linkButton>
                    <Link to="/plumbing" className={"dropdown-item"}>Plumbing</Link>
                </ListItem>
                <ListItem component={Link} to={"computers"} linkButton>
                    <Link to="/computers" className={"dropdown-item"}>Computers</Link>
                </ListItem>
            </List>
        </div>

        /*{/!*<div className={`list-group col-lg-2 col-md-3 col-sm-6 col-xs-3 offset-1 align-content-center`}
             style={{marginTop: '2rem', fontSize:'1.2rem', flexDirection:flag?'row' :'column'}}>
            <Link to={"/"}>
                <div className="list-group-item alert-link likiclass">All</div>
            </Link>
            <Link to={"/led"}>
                <div className="list-group-item alert-link list-group-item-dark likiclass">LED</div>
            </Link>
            <Link to={"/plumbing"}>
                <div className="list-group-item alert-link list-group-item-dark likiclass">Plumbing</div>
            </Link>
            <NavLink to={"/computers"}>
                <div className="list-group-item alert-link list-group-item-dark likiclass">Computers</div>
            </NavLink>
        </div>*!/}*/
    )
};
export default ProductsNavigator;
