import Card from "react-bootstrap/Card";
import React from "react";
import {Button, Image} from "react-bootstrap";
import '../App.css';
// import '../bootstrap/bootstrap.min.css';
import {Link} from "react-router-dom";

const Product = ({product, addToCart, addToWishlist}) => {

    return (
        //lg={3} md={6} sm={9} xs={12}  className="col-lg-3 col-md-6 mb-4"
        <React.Fragment>
            <Card className={"likiclass"} style={{height: '100%'}} key={product.id}>
                <Card.Header>{product.name}</Card.Header>
                <div style={{width: "100%", height: "70%"}}>
                    <Card.Body>
                        <Link to={"product/" + product.id}>
                            <Image src={product.image}
                                   style={{width: "100%", height: "205px", display:'block'}}
                                   alt={product.id}
                            />
                        </Link>
                        <button className="btn pmd-btn-fab pmd-ripple-effect btn-outline-danger showme"
                                type="button"
                                style={{position: "absolute", top: "205px", right: "10px"}}
                                onClick={() => addToWishlist(product)}>
                            <i className="material-icons pmd-sm" style={{lineHeight: "unser"}}>favorite_border</i>
                        </button>

                        {product.description.substring(0, 55)}
                    </Card.Body>
                </div>
                <Card.Body as={'h5'}>
                    {product.price} MKD
                </Card.Body>
                <Card.Footer>
                    <Button style={{marginRight: '1rem'}} variant={'success'}
                            onClick={() => addToCart(product)}>
                        Add to cart!
                    </Button>

                    {/*<Button style={{marginLeft: '1rem'}} variant={'success'} size={"md"}*/}
                    {/*onClick={() => Product.addToWishlist(this.props.value.id)}>*/}
                    {/*Wishlist!*/}
                    {/*</Button>*/}
                </Card.Footer>
            </Card>
        </React.Fragment>
    );


}

export default Product;

//
// <Card className={classes.root}>
//     <CardActionArea>
//         <CardMedia
//             className={classes.media}
//             image="/static/images/cards/contemplative-reptile.jpg"
//             title="Contemplative Reptile"
//         />
//         <CardContent>
//             <Typography gutterBottom variant="h5" component="h2">
//                 Lizard
//             </Typography>
//             <Typography variant="body2" color="textSecondary" component="p">
//                 Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
//                 across all continents except Antarctica
//             </Typography>
//         </CardContent>
//     </CardActionArea>
//     <CardActions>
//         <Button size="small" color="primary">
//             Share
//         </Button>
//         <Button size="small" color="primary">
//             Learn More
//         </Button>
//     </CardActions>
// </Card>
