export const FETCH_ALL_PRODUCTS = 'FETCH_ALL_PRODUCTS';
export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const FETCH_PRODUCT_REVIEWS = 'FETCH_PRODUCT_REVIEWS';
export const ADD_PRODUCT_REVIEW = 'ADD_PRODUCT_REVIEW';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';


export const FETCH_WISHLIST_PRODUCTS = 'FETCH_WISHLIST_PRODUCTS';
export const ADD_WISHLIST_PRODUCT = 'FETCH_WISHLIST_PRODUCT';
export const DELETE_WISHLIST_PRODUCT = 'DELETE_WISHLIST_PRODUCT';


export const FETCH_CART_PRODUCTS = 'FETCH_CART_PRODUCTS';
export const ADD_CART_PRODUCT = 'ADD_CART_PRODUCT';
export const DELETE_CART_PRODUCT = 'DELETE_CART_PRODUCT';
export const SET_PRODUCT_QUANTITY = 'SET_PRODUCT_QUANTITY';
//todo: increase / decrease count


export const SET_USER = 'SET_USER';
