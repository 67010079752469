import firebase from "../firebase/firebase";
import admins from "../admin/admins.json";

export const replaceInArray = (object, array) => {
    const indexOf = array.indexOf(object.id)
    const firstHalf = array.slice(0, indexOf);
    const secondHalf = array.slice(indexOf);
    return [...firstHalf, object, ...secondHalf];
}

export const isAdmin = () => {
    return firebase.auth().currentUser &&
        admins.includes(firebase.auth().currentUser.uid)
}
