import React, {useEffect, useState} from "react";
import '../App.css';
import {Container} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Repository from "../repository/repository";
import {useHistory, useParams} from "react-router-dom";
import {isAdmin} from "../utils/utils";
import {useDispatch, useSelector} from "react-redux";
import {ProductActions} from "../store/actions/productActions";

const AddEditProduct = () => {
    const history = useHistory();
    const {productId} = useParams();
    const dispatch = useDispatch();
    const edit = Boolean(productId);

    const product = useSelector(state => state.product.products.find(
        product => product.id === productId
    ));

    const [productForm, setProductForm] = useState({});
    const [image, setImage] = useState({});
    const [localImage, setLocalImage] = useState({});
    let changedImage = false;

    useEffect(() => {
        if (productId != null && !product) {
            dispatch(ProductActions.fetchSingleProduct(productId));
        }
        if (product) {
            setImage(product.image);
            setLocalImage(product.image);
            setProductForm(product)
        }
    }, [product]);

    const handleTermOnChange = (e) => {
        const paramName = e.target.name;
        const paramValue = e.target.value;
        setProductForm({
            ...productForm,
            [paramName]: paramValue
        });
    }


    async function onFormSubmit(e) {
        e.preventDefault();
        let img = document.getElementsByName("imshow").item(0).getAttribute("src");
        let product = {
            name: e.target.name.value,
            countininventory: e.target.inventory.value,
            price: e.target.price.value,
            description: e.target.description.value,
            category: e.target.category.value,
            image: img
        };

        if (image != localImage)
            product.image = await Repository.addProductImage(image);

        if (!edit) {
            dispatch(ProductActions.addProduct(product))
        } else {
            dispatch(ProductActions.updateProduct(productId, product));
        }
        history.push("/admin/panel")
    }


    async function changeImg(e) {
        e.preventDefault();
        const file = document.getElementsByName("img")[0].files[0];
        setImage(file);
        setLocalImage(URL.createObjectURL(file));
        changedImage = true;
    }

    let onChangeFunction;
    if (edit)
        onChangeFunction = handleTermOnChange;

    if (isAdmin())
        return (
            <form onSubmit={onFormSubmit} encType="multipart/form-data" className="md-form">
                <div className="container">
                    <div className="container-fluid">
                        <div className="row no-gutter">
                            <div className="col-md-8 col-lg-6">
                                <div className="login d-flex align-items-center py-5">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-9 col-lg-8 mx-auto">
                                                <h3 className="login-heading mb-4">New Product</h3>
                                                <div className="form-label-group">
                                                    <input type="text" id="name" className="form-control"
                                                           placeholder="Product name"
                                                           name="name" required={!edit} autoFocus={true}
                                                           value={product && product.name}
                                                           onChange={onChangeFunction}
                                                    />
                                                </div>
                                                <div className="form-label-group">
                                                    <input type="number" id="inventory" className="form-control"
                                                           placeholder="Inventory count"
                                                           name="inventory" required={!edit}
                                                           value={product && product.countininventory}
                                                           onChange={onChangeFunction}

                                                    />
                                                </div>

                                                <div className="form-label-group">
                                                    <input type="number" id="price" className="form-control"
                                                           placeholder="Product price $$$"
                                                           name="price" required={!edit}
                                                           value={product && product.price}
                                                           onChange={onChangeFunction}

                                                    />
                                                </div>
                                                <div className="form-label-group">
                                                    <input type="text" id="description" className="form-control"
                                                           name="description" placeholder="Product description"
                                                           required={!edit}
                                                           value={product && product.description}
                                                           onChange={onChangeFunction}
                                                    />
                                                </div>
                                                <div className="form-label-group">
                                                    <select className="custom-select"
                                                            id="category"
                                                            name="category"
                                                            required={!edit}
                                                            value={product && product.category}
                                                            onChange={onChangeFunction}>
                                                        <option disabled={true} selected={true}></option>
                                                        <option value="LED">Lights</option>
                                                        <option value="Computers">Computers</option>
                                                        <option value="Plumbing">Plumbing</option>
                                                    </select>
                                                </div>
                                                {/* <div className="form-label-group">
                                                <input type="text" id="keywords" className="form-control"
                                                       name="keywords" placeholder="Keywords: led yellow white ..."
                                                       required={!edit} value={product.keywords}
                                                       onChange={onChangeFunction}
                                                />
                                            </div>*/}
                                                <button
                                                    className="btn mt-3 btn-success btn-lg btn-block btn-login font-weight-bold mb-2"
                                                    type="submit">
                                                    SUBMIT
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Container className="col-sm-4" style={{marginTop: "2rem"}}>
                                <Col className="imagePreview">
                                    <img name="imshow" width={"350px"} height={"350px"}
                                         src={localImage}
                                    />
                                </Col>
                                <Col>
                                    <input type="file" name="img" id={"img"} className="btn btn-primary text-light"
                                           onChange={changeImg}
                                           placeholder={"Upload"}
                                           required={!edit} style={{overflow: " hidden"}}
                                    />
                                </Col>
                            </Container>
                        </div>
                    </div>
                </div>
            </form>
        )
    else
        return ("Not authorized")
};
export default AddEditProduct;
