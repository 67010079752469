import React, {useEffect} from "react";
import WishlistItem from "./WishlistItem";
import "../bootstrap/bootstrap.min.css";
import {Container} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {WishlistActions} from "../store/actions/wishlistActions";
import Row from "react-bootstrap/Row";

const WishlistGroup = () => {

    const dispatch = useDispatch();
    const wishlist = useSelector(state => state.wishlist.wishlist);
    const user = useSelector(state => state.auth.user);

    useEffect(() => {
        if (user && user.uid) {
            dispatch(WishlistActions.fetchAllWishlistProducts(user.uid))
        }
    }, [user])

    const deleteWishlistItem = (productId) => {
        if (user && user.uid) {
            dispatch(WishlistActions.deleteWishlistProduct(productId, user.uid))
        }
    };

    return (
        <React.Fragment>
            {!user ? <Container style={{textAlign: "center", paddingTop: "3rem"}}>
                    <h1>
                        Sign in for this Feature
                    </h1>
                </Container>
                :
                (<React.Fragment>
                    {
                        wishlist && wishlist.length > 0 ?
                            <div className={"container"} style={{paddingTop: "2rem"}}>
                                <Row>
                                    {wishlist && wishlist.map(wishlistItem =>
                                        <WishlistItem product={wishlistItem} key={wishlistItem.id}
                                                      onDelete={deleteWishlistItem}/>)}
                                </Row>
                            </div>
                            :
                            <div style={{textAlign: "center", alignContent: "center"}}>
                                <h1 style={{padding: '2rem'}}>
                                    Your wishlist is empty
                                </h1>
                                <img src={"/empty-wishlist.png"}/>
                            </div>
                    }
                </React.Fragment>)
            }
        </React.Fragment>
    )
}

export default WishlistGroup;
