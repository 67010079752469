import {ADD_CART_PRODUCT, DELETE_CART_PRODUCT, FETCH_CART_PRODUCTS, SET_PRODUCT_QUANTITY} from "../actions/actionTypes";
import {replaceInArray} from "../../utils/utils";

const initialState = {
    cart: []
}

export const cartReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CART_PRODUCTS: {
            return {
                ...state,
                cart: action.cart
            }
        }
        case ADD_CART_PRODUCT: {
            return {
                ...state,
                cart: [state.cart, action.product]
            }
        }
        case DELETE_CART_PRODUCT: {
            const newCart = state.cart.filter(product => product.id !== action.productId);
            return {
                ...state,
                cart: newCart
            }
        }
        case SET_PRODUCT_QUANTITY: {
            const product = state.cart.find(product => product.id === action.productId)
            const newProduct = {
                ...product,
                quantity: action.quantity
            }
            return {
                ...state,
                cart: replaceInArray(newProduct, state.cart)
            }
        }
        default:
            return state;
    }
}
