import Grid from "@material-ui/core/Grid";
import React from "react";
import {makeStyles} from "@material-ui/core";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
    root: {
        padding: '3%'
    },
    img: {
        maxWidth: '100%',
    },
    centerText: {
        textAlign: 'center'
    },
    center: {
        margin: 'auto'
    },
    white: {
        color: 'white',
        '&:hover': {
            color: '#FFF'
        }
    },
    width: {
        width: '100%'
    }
})

const Dostava = ({}) => {
    const classes = useStyles();
    return (
        <Grid container className={classes.root}
              alignContent='center'
              alignItems='center'
              justify='center' spacing={3}>
            <Grid item lg={4}>
                <img src={"/cargo.png"} className={classes.img}/>
            </Grid>
            <Grid item lg={5}>
                <h2 className={[classes.width, classes.centerText]}>
                    Вршиме Достава низ цела Македонија по Карго
                </h2>
                <h4 className={[classes.centerText]}>
                    За Контакт и повеќе информации
                </h4>
                <div className={[classes.width]}>
                    <Button component={Link}
                            variant="contained"
                            to='/contact'
                            color='primary'
                            size='large'
                            className={[classes.center, classes.white]}
                            style={{marginLeft: '40%'}}
                    >
                        Контакт
                    </Button>
                </div>
            </Grid>
        </Grid>
    )
}
export default Dostava;
