import {
    ADD_PRODUCT,
    ADD_PRODUCT_REVIEW,
    DELETE_PRODUCT,
    FETCH_ALL_PRODUCTS,
    FETCH_PRODUCT,
    FETCH_PRODUCT_REVIEWS,
    UPDATE_PRODUCT
} from "../actions/actionTypes";

const initialState = {
    products: [],
    reviews: []
}

export const productsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_PRODUCTS: {
            return {
                ...state,
                products: action.products
            }
        }
        case FETCH_PRODUCT: {
            return {
                ...state,
                products: [...state.products, action.product]
            }
        }
        case FETCH_PRODUCT_REVIEWS: {
            const otherReviews = state.reviews.filter(review => review.id !== action.productId)
            return {
                ...state,
                reviews: [...otherReviews, action.reviews]
            }
        }
        case ADD_PRODUCT_REVIEW: {
            return {
                ...state,
                reviews: [...state.reviews, action.review]
            }
        }
        case ADD_PRODUCT: {
            return {
                ...state,
                products: [state.products, action.product]
            }
        }
        case DELETE_PRODUCT: {
            const newProducts = state.products.filter(product => product.id !== action.id);
            return {
                ...state,
                products: newProducts
            }
        }
        case UPDATE_PRODUCT: {
            const newProducts = state.products.filter(product => product.productId !== action.product.id);
            return {
                ...state,
                products: [newProducts, action.product]
            }
        }
        default:
            return state;
    }
}
