import {MDBBtn, MDBInput} from "mdbreact";
import StarRatings from "react-star-ratings";
import React, {useState} from "react";
import firebase from "../firebase/firebase";
import {ProductActions} from "../store/actions/productActions";
import {useDispatch} from "react-redux";


const ReviewForm = ({productId}) => {
    const dispatch = useDispatch();

    const [state, setState] = useState({
        rating: 0,
        reviewText: ''
    })

    const changeRating = (newRating) => {
        setState({
            ...state,
            rating: Math.ceil(newRating)
        });
    };

    const changeReviewText = (event) => {
        console.log(event)
    }

    const postReview = (e) => {
        let review = {
            comment: state.reviewText,
            rate: state.rating,
            dateLong: Date.now(),
            user: firebase.auth().currentUser.displayName,
            productId
        };
        dispatch(ProductActions.addProductReview(review))
    };

    return (
        <form onSubmit={postReview} style={{marginTop: "0px"}}>
            <div className={"col-lg-7"}
                 style={{backgroundColor: "#F9F9F9", padding: '0px', overflow: "hidden", marginTop: "0px"}}>

                <MDBInput type="textarea" label="Your review" background
                          onChange={changeReviewText}
                          style={{
                              width: "100%",
                              padding: "0px",
                              position: "relative",
                              top: "0px",
                              marginTop: "0px"
                          }} name={"comment"}/>
                <StarRatings
                    rating={state.rating}
                    starRatedColor="#ffa000"
                    starHoverColor="#ffa000"
                    starDimension={"30px"}
                    changeRating={changeRating}
                    numberOfStars={5}
                    name='rating'
                    style={{padding: "1rem", display: "inline-block", justifyContent: "space-between"}}
                />
                <MDBBtn color="success" type="submit" style={{
                    display: "inline-block",
                    justifyContent: "space-between",
                    float: "right"
                }}>Submit</MDBBtn>
            </div>
        </form>
    )
}
export default ReviewForm;
