import React from "react";

const Project = (props) => {

    return (
        <div className="col-lg-4 col-md-4 col-sm-12">
            <h4>{props.title}</h4>
            {/*<p>{props.title}</p>*/}
            <img src={props.image} alt="Image" style={{maxWidth: 350}} height="300"/>
            <p className={"mt-2"}>{props.description}</p>
        </div>
    )

};
export default Project;

