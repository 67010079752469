import Card from "react-bootstrap/Card";
import React from "react";
import {Button, Image} from "react-bootstrap";
import '../App.css';
import {Link} from "react-router-dom";

const AdminProduct = ({product, onDelete}) => {

    return (
        <div className={"col-lg-3 col-md-4 mb-4"}>
            <Card className="likiclass" style={{height: '100%'}} key={product.id}>
                <Card.Header>{product.name}</Card.Header>
                <div style={{width: "100%", height: "70%"}}>
                    <Card.Body>
                        <Link to={"/product/" + product.id}>
                            <Image src={product.image}
                                   style={{width: "100%", height: "205px"}}
                                   alt={product.id}
                            />
                        </Link>
                        {product && product.description && product.description.substring(0, 55)}
                    </Card.Body>
                </div>
                <Card.Body as={'h5'}>
                    {product.price} MKD
                </Card.Body>
                <Card.Footer className={"d-flex"}>
                    <Button style={{marginRight: '1rem', padding: "0.7rem"}} variant={'danger'}
                            onClick={() => onDelete(product.id)}
                    >
                        Remove Product
                    </Button>
                    <Link className="btn btn-yellow"
                          type="button"
                          style={{padding: "0.7rem"}}
                          to={'editproduct/' + product.id}
                    >
                        Edit Product
                    </Link>

                </Card.Footer>
            </Card>
        </div>
    );

}

export default AdminProduct;
