import React, {useEffect} from "react";
import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import AdminProduct from "./AdminProduct";
import Col from "react-bootstrap/Col";
import {Link} from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import {useDispatch, useSelector} from "react-redux";
import {ProductActions} from "../store/actions/productActions";
import PageNotFound from "../pageNotFound/PageNotFound";
import {isAdmin} from "../utils/utils";

const AdminPanel = () => {

    const dispatch = useDispatch();
    const products = useSelector(state => state.product.products)
    const user = useSelector(state => state.auth.user);

    useEffect(() => {
        if (user && user.uid) {
            dispatch(ProductActions.fetchProducts());
        }
    }, [user])

    const deleteProduct = (productId) => {
        if (user && user.uid) {
            dispatch(ProductActions.deleteProduct(productId));
        }
    };


    return (
        <React.Fragment>
            {isAdmin() ?
                (<Row>
                    <Col className="col-lg-2">
                        <Link to="newproduct"
                              style={{marginTop: "5rem"}}
                              className={"btn btn-success"}>
                            Add new product
                        </Link>
                    </Col>
                    <Col className={"col-lg-9"}>
                        <Container style={{marginTop: "2rem"}}>
                            {products ? (
                                    <Row>
                                        {products &&
                                        products.map(product =>
                                            <AdminProduct product={product}
                                                          key={product.id}
                                                          onDelete={deleteProduct}/>)}
                                    </Row>
                                ) :
                                (<Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>)
                            }
                        </Container>
                    </Col>
                </Row>)
                :
                <PageNotFound/>
            }
            )
        </React.Fragment>
    )
}

export default AdminPanel;
