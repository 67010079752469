import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MaterialLink from "@material-ui/core/Link";
import {Link} from 'react-router-dom'

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

export default function AdminDropDown() {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                color="primary"
                style={{marginLeft: '10px'}}
                onClick={handleClick}
            >
                Admin
            </Button>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {/*<MaterialLink to="/admin/contacts" component={Link}>*/}
                {/*    <MenuItem>*/}
                {/*        <ListItemIcon>*/}
                {/*            <DraftsIcon fontSize="small"/>*/}
                {/*            <ListItemText primary="Emails"/>*/}
                {/*        </ListItemIcon>*/}
                {/*    </MenuItem>*/}
                {/*</MaterialLink>*/}
                <MaterialLink to="/admin/panel" component={Link}>
                    <MenuItem>
                        <ListItemIcon>
                            <InboxIcon fontSize="small"/>
                            <ListItemText primary="Products"/>
                        </ListItemIcon>
                    </MenuItem>
                </MaterialLink>
            </StyledMenu>
        </div>
    );
}
