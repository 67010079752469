import {productsReducer} from "./reducers/productsReducer";
import {cartReducer} from "./reducers/cartReducer";
import {wishlistReducer} from "./reducers/wishlistReducer";
import {applyMiddleware, combineReducers, createStore} from "redux";
import thunk from "redux-thunk";
import {authReducer} from "./reducers/authReducer";

const rootReducer = combineReducers({
    product: productsReducer,
    cart: cartReducer,
    wishlist: wishlistReducer,
    auth: authReducer
})

export const store = createStore(rootReducer, applyMiddleware(thunk))
