import {SET_USER} from "./actionTypes";

export const AuthActions = {
    setAuthState: (user) => dispatch => {
        dispatch({
            type: SET_USER,
            user
        })
    }
}
